.deviceCard {
    box-sizing: border-box;
    border: 1px solid #E4E4E4;
    border-radius: 1em;

    width: 100%;
    max-width: 316px;

    &.isLink:hover {
        cursor: pointer;
        background-color: #FAFAFA;
    }
}

.heading {
    font-size: 1.125em;
}

.firstsubheading {
    font-size: 1em;
}

.secondsubheading {
    font-size: 1em;
}

.detail {
    font-size: 0.75em;
    color: #989898;
    text-align: center;
    margin-top: 23.5pt;
    margin-bottom: 0pt;
}

.detail .svg-inline--fa {
    height: 1.5em;
    float: right;
    margin-left: 5px;
}

.statusContainer {
    display: flex;
    flex-direction: column;
    justify-content: right;
}