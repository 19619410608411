.swagger-ui .topbar {
    background-color: #003399;
}

.swagger-ui .try-out {
    display: none !important;
}

.swagger-ui .models {
    display: none !important;
}

.swagger-ui .btn.authorize {
    display: none !important;
}

/* Post block */
.swagger-ui .opblock.opblock-post {
    border-color: #64A70B;
    background-color: #F3F8EC;
}

/* lower border for post */
.swagger-ui .opblock.opblock-post .opblock-summary {
    border-color: #64A70B;
}

/* Post button */
.swagger-ui .opblock.opblock-post .opblock-summary-method {
    background-color: #64A70B;
}

/* Get block */
.swagger-ui .opblock.opblock-get {
    border-color: #001996;
    background-color: #F3F4FA;
}

/* lower border */
.swagger-ui .opblock.opblock-get .opblock-summary {
    border-color: #001996;
}

/* Get button */
.swagger-ui .opblock.opblock-get .opblock-summary-method {
    background-color: #001996;
}

.swagger-ui .info {
    display: none;
}

.swagger-ui .scheme-container .schemes>label {
    display: none;
}

.swagger-ui .scheme-container {
    box-shadow: none;
    padding: none;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div {
    display: flex;
    flex-direction: column;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div>span:nth-child(1) {
    order: 1;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div>span:nth-child(2) {
    order: 3;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div>span:nth-child(3) {
    order: 4;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div>span:nth-child(4) {
    order: 2;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div>span:nth-child(1)>div.opblock-tag-section.is-open>div {
    display: flex;
    flex-direction: column;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div>span:nth-child(1)>div.opblock-tag-section.is-open>div>span:nth-child(1) {
    order: 2;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div>span:nth-child(1)>div.opblock-tag-section.is-open>div>span:nth-child(2) {
    order: 3;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div>span:nth-child(1)>div.opblock-tag-section.is-open>div>span:nth-child(3) {
    order: 5;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div>span:nth-child(1)>div.opblock-tag-section.is-open>div>span:nth-child(4) {
    order: 1;
}

.swagger-ui .block.col-12.block-desktop.col-12-desktop>div>span:nth-child(1)>div.opblock-tag-section.is-open>div>span:nth-child(5) {
    order: 4;
}