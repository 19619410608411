.collapse-icon {
    display: contents;
}

.collapse-icon svg {
    margin-right: 15px;
}

.group-name {
    align-items: center;
    font-size: 18px;
    font-weight: bold;
    background: #F0F0F0;
}

.group-name p {
    margin: 5px;
}