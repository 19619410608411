.headerContainer {
    border-bottom: 1px solid #CECECE;
}

.text-subtitle {
    font-weight: 600;
    color: #989898;
}

#backlink:hover {
    max-width: max-content;
    cursor: pointer;
}