.digiLogo {
    height: 28px;
}

.float-right {
    float: right;
}

.loginContainer {
    height: 100vh;
    background: linear-gradient(#167AC2, #0D49A8, #001996);
}

.sortMarshalLogo {
    padding-left: 35px;
    padding-top: 25px;
}

.loginButton {
    color: #001996;
    height: 45px;
    width: 230px;
    font-size: 13px;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.loginButton:hover {
    color: #001996;
    background: #DCDCDC;
}

.poweredByLogo {
    margin: 0;
    position: absolute;
    top: 90%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}