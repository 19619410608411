.selectorTitle {
    color: #707070;
}

.groupSelector {
    background-color: #FFFFFF;
    color: #000000;
    border: 1px solid #BEBEBE;
}

.groupSelector:hover {
    background-color: #E4E4E4;
    color: #000000;
    border: 1px solid #BEBEBE;
}

.groupSelector.selected {
    background-color: #303030;
    color: #FFFFFF;
    border-color: transparent;
}