.dropdown-container {
  position: relative;
  min-height: 36px;
}

.dropdown {
  position: relative;
}

.dropdown-button {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: flex-start;
  line-height: 2.5;
  width: 100%;
  min-height: 36px;
  padding-top: 3px;
  padding-left: 0px;
  padding-right: 6px;
  border: 1px solid #BEBEBE;
  border-radius: 4px;
  background-color: #fff;
  cursor: pointer;
}

.selected-item {
  padding: 3px 10px 3px 6px;
  margin-right: 7px;
  white-space: nowrap;
  border-radius: 100px;
  background: #DCE6FF;
  color: #001996;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.dropdown-icon {
  position: absolute;
  top: 25%;
  right: 8px;
  margin-left: 8px;
  font-size: 1.2em;
}

.clear-icon {
  margin-left: 8px;
  color: #001996;
  cursor: pointer;
  font-style: normal;
  font-weight: 100;
}

.span-style {
  margin-right: 6px;
  margin-left: 10px;
  padding: 0;
}

.add-new-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #303030;
  width: 20px;
  height: 20px;
  border: 1.5px solid #303030;
  border-radius: 50%;
  margin-right: 22px;
  margin-left: 18px;
  background-color: transparent;
  cursor: pointer;
}

.add-new-button:hover {
  background-color: #DCE6FF;
}

.add-item {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.add-item:hover {
  text-decoration: underline;
  cursor: pointer;
}

.dropdown-options {
  position: absolute;
  align-items: center;
  top: 100%;
  left: 0;
  width: 100%;
  padding: 8px 8px 0px 8px;
  background-color: #fff;
  border: 1px solid #ccc;
  z-index: 1;
  margin-top: 10px;
  border-radius: 4px;
  max-height: 300px;
  /* Adjust this value to control the maximum height */
  overflow-y: auto;
  /* Add a scroll bar when content exceeds max-height */
  overflow-x: hidden;
  /* Hide the horizontal scroll bar */
}


.dropdown-options input[type="radio"],
.dropdown-options input[type="checkbox"] {
  margin: 10px 20px 0px 18px;
  width: 20px;
  height: 20px;
}

.dropdown-options label {
  cursor: pointer;
  align-items: center;
  vertical-align: middle;
  margin-right: 20px;
}

.items-alignment {
  line-height: 40px;
  display: flex;
}

.dropdown-button:hover {
  background-color: #f2f2f2;
}

.dropdown-button:active {
  background-color: #e6e6e6;
}

.clear-icon:hover {
  color: red;
}

.dropdown.open .dropdown-options {
  display: block;
}

.save-button-container {
  background-color: #FFF;
  padding: 17px 17px 17px 16px;
  position: sticky;
  bottom: 0;
}

.save-button {
  display: block;
  /* margin: 33px 17px 17px 16px; */
  margin: auto;
  width: 90%;
  height: 43px;
  padding: 8px 16px;
  background-color: black;
  color: #FFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.search-input-container {
  position: relative;
  margin: 20px 17px 12px 18px;
  height: 36px;
}

.search-input-container input[type="text"] {
  padding-left: 40px;
  /* Add padding to accommodate the search icon */
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 4px;
  height: 36px;
}

.input-style {
  width: 100%;
}

/* .search-input-container input[type="text"]::after{
  border: 1px solid green; 
} */


.search-icon {
  position: absolute;
  top: 30%;
  left: 15px;
  /* transform: translateY(-50%); */
  pointer-events: none;
  color: #BEBEBE;
  align-items: center;

}

.multi-select-controls {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
  margin-bottom: 10px;
}

.selected-count {
  margin-right: 8px;
  margin-left: 18px;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: #303030;

}

.clear-all-button {
  background-color: transparent;
  border: none;
  font-size: 13px;
  color: #001996;
  cursor: pointer;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-right: 12px;
}

.clear-all-button:hover {
  text-decoration: underline;
}