.text-blue {
  color: #001996;
}

.fileTypeIcon {
  max-width: max-content;
}

.queryBox {
  border: 1px solid #BEBEBE;
  border-radius: 0.75em;
  max-width: fit-content;
}

.row {
  display: flex;
}

.column {
  flex: 50%;
}

.connector-save-container {

  border-top: 1px solid #CECECE;
  width: 100%;
  margin-left: 0;
  padding-top: 1.5rem;
}

.save-text {
  font: 18px;
  padding-left: 10px;

}

.custom-button {
  height: 43px;
  position: center;
  padding: 10px, 20px 12px 20px;
  background-color: #001996;
  color: #FFF;
  border-radius: 5px;
  border: 1px solid #C1C1C1;
  cursor: pointer;
  font: 16px;
  white-space: nowrap;

}

.custom-button:hover {
  background-color: black;
}

.custom-button[disabled] {
  /* Apply styles for disabled buttons */
  background-color: #c1c1c1;
  cursor: not-allowed;
}

.save-bar {
  margin-right: 10%;
}

.check-mark-icon {
  color: green;
  height: 1.2rem;
}

.cancel-mark-icon {
  color: red;
  height: 1.2rem;
}

.other-clients-row {
  padding: 8px 0;
  border: 1px solid #BEBEBE;
}
.other-clients-row:hover {
  background: #D8D8D8;
  color: #303030;
}

.header-row {
  background: #F0F0F0;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 8px 0;
  border-left: 1px solid #BEBEBE;
  border-right: 1px solid #BEBEBE;
  border-top: 1px solid #BEBEBE;
}

.custom-hyperlink {
  text-decoration: none;
  color: #303030;
}

.platform-icon {
  font-size: 20px;
}