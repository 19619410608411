.deviceStatus {
    background-color: #F0F0F0;
    color: #303030;
    font-size: 0.875em;
    font-weight: 600;
}

.deviceStatus.online {
    background-color: #64A70B;
    color: #FFFFFF;
    font-weight: 600;
}