@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');

.sidebarItem {
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
}

.sidebarItem .active {
    color: #001996 !important;
    background: #0019961A !important;
    border-radius: 4px;
}

.sidebarItem svg {
    fill: #7D7B7B;
}

.sidebarItem:hover {
    background-color: #7D7B7B1A;
    border-radius: 4px;
}

.sidebarItem .active svg {
    fill: #001996;
}

.sidebarHeader {
    padding-left: 20px;
    width: 80%;
}

.side-icon {
    vertical-align: text-bottom;
}

.spinner-container {
    padding-left: 20px;
}