@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

body {
    font-family: 'Open Sans';
}

.fs-7 {
    /* TODO: Bootstrap blends ems and vws for fontsizes. We can either copy its calculation, or use the SASS mixin to fix this class. */
    font-size: 0.875em;
}

// Importing sections of bootstrap (importing just "bootstrap" will break npm run build)

@import "../../node_modules/bootstrap/scss/functions";

// 2. Include any default variable overrides here
$primary: #001996;

// 3. Include remainder of required Bootstrap stylesheets
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";

// 4. Include any optional Bootstrap components as you like
@import "../../node_modules/bootstrap/scss/buttons";