@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 7px solid #BEBEBE;
    border-top: 7px solid #303030;
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }